import React, { useState } from 'react';
import {ethers} from 'ethers';
import contract from './abi/MultiSender.js';
import Details from './components/details';

import './App.css';

function App() {
    //Ethers
 

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const [defaultAccount, setDefaultAccount] = useState(null);

    const connectwalletHandler = () => {
        if (window.ethereum) {
            provider.send("eth_requestAccounts", []).then(async () => {
                await accountChangedHandler(provider.getSigner());
            })
        } else {
            alert("Please Install Metamask!!!");
        }
    }
    const accountChangedHandler = async (newAccount) => {
        const address = await newAccount.getAddress();
        setDefaultAccount(address);
        const balance = await newAccount.getBalance()
        
    }
    const signer = provider.getSigner();

    const Contract = new ethers.Contract(contract.address, contract.abi, signer);

    //Token Contract
    let tokenContract;
    let tokenDecimals;
    const tokenAbi = [
        "function name() view returns (string)",
        "function symbol() view returns (string)",
        "function balanceOf(address) view returns(uint)",
        "function transfer(address to, uint amount)",
        "function approve(address spender, uint amount) returns(bool)",
        "function decimals() view returns (uint8)"
    ];

    const [erc20, setErc20] = useState('');
    const [recipients, setRecipients] = useState([]);

    //Tx Status
    const [txState, setTxState] = useState(0); 

    function statusBar(state) {
        const style = {
            height: '10px',
            width:'10px',
            borderRadius: '90%',
            border: 'solid 2px',
            backgroundColor: 'white',
            margin: '0 auto'
        }
        if(txState === state) {
            style.backgroundColor = 'blue';
        }
        return style;
    };

    async function updateState() {
        if(txState === 2) {
            //Send Tokens and reset
            try{
                const addrs = recipients.map(x => x.address);                
                const Amounts = recipients.map(x => parseInt (x.amount*1000000000));
                console.log(addrs,Amounts,'erc20',erc20)
                const tx2 = await Contract.multiSender(addrs, Amounts, erc20);
                await tx2.wait();
                setTxState(0);
                alert('Transaction Send Successfully');
            } catch(ex) {
                console.log(ex,60)
                alert('There was a problem sending the transaction, Please try again!');
            }
        } else if(txState === 0){
            try{
                console.log(67,erc20)
                if(erc20==''){
                    const addrs = recipients.map(x => x.address);
                    const Amounts = recipients.map(x => parseInt (x.amount*1000000000));
                    console.log(68,recipients,addrs,Amounts,'xxxxx',Amounts.reduce((a, b) => a + b, 0)/10**9)
                    const options = {value: ethers.utils.parseEther(""+Amounts.reduce((a, b) => a + b, 0)/10**9)}
                    const tx2 = await Contract.multiSenderBase(addrs, Amounts,options);
                    await tx2.wait();
                    alert('successfully transferred cfx')
                }else{
                    console.log(74)
                    const accounts = await provider.send("eth_requestAccounts", []);
                    console.log(11,provider,accounts)
                    //Set Token Contract 
                    console.log('erc20',erc20)
                    tokenContract = new ethers.Contract(erc20,tokenAbi,signer);
                    console.log(65,tokenContract)
                    await tokenContract.decimals();
                    console.log(66,tokenDecimals)
                    setTxState(1);
    
                    //Approve amounts
                    const amounts = recipients.map(x => Number(x.amount));
                    console.log(72,amounts)
                    const tx1 = await tokenContract.approve(contract.address, ethers.utils.parseUnits(amounts.reduce((a, b) => a + b, 0).toString(), tokenDecimals));
                    console.log(74,tx1)
                    await tx1.wait();
                    setTxState(2);
                }
              
            } catch (err) {
                console.log(78,err,'79')
                const errString = String(err);
                if(err.code === 4001){setTxState(0)}
                else if(errString.indexOf('ENS') !== 0) {alert('Incorrect token address, or not connect the wallet')}
                    //alert('Transaction not approved: Make sure all the values in the recipient file are correct');
            }
        }
    }

    return ( 
    <div className="App">

        <header className="App-header">

        <button
                style={{ background: defaultAccount ? "#A5CC82" : "white" }}
                onClick={connectwalletHandler}>
                {defaultAccount ? "Connected!!" : "Connect"}
            </button>
            <div className="displayAccount">
                <h4 className="walletAddress">Address:{defaultAccount}</h4>
            </div>

            <h1>Batch Transfer Tool (Created by ABC Pool)</h1>
            <p>
                Send any ERC20 token to multiple users !
            </p>
            <p>Conflux eSpace </p>

            <div style={{display:'flex'}}>
                <div className='statusBar' style={{padding:'10px'}}>
                    <div style={statusBar(0)}></div>
                    <p>Details</p>
                </div>
                <div className='statusBar' style={{padding:'10px'}}>
                    <div style={statusBar(1)}></div>
                    <p>Approve</p>
                </div>
                <div className='statusBar' style={{padding:'10px'}}>
                    <div style={statusBar(2)}></div>
                    <p>Send</p>
                </div>
            </div>
            {txState === 0 && 
            <div>
            <Details 
                tokenAddress={erc20} 
                setTokenAddress={erc20 => setErc20(erc20)}
                setRecievers={recipients => setRecipients(recipients)}
        />
                <button onClick={updateState}>Approve</button>
            </div>
            }

            {txState === 1 &&
            <p>Transaction is being approved...</p>
            }

            {txState === 2 && 
                <div>
                    <p>Send Transaction</p>
                    <button onClick={updateState}>Send</button>
                </div>
            }
        </header>
        <hr></hr>
        <h1>How to use it</h1>
        <img src='/howtouse.png'/>
    </div>
    );
}

export default App;
