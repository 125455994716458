import CodeMirror from '@uiw/react-codemirror';
import 'codemirror/keymap/sublime';
import 'codemirror/theme/monokai.css';
import 'codemirror/mode/javascript/javascript';

function Details(props) {

    function getContent(e) {
        const maxLines = e.doc.lineCount();
        props.setRecievers([]);
        
        // Convert to json
        for(let i=0; i<maxLines; i++) {
            let line = e.doc.getLine(i).replace( /\s/g, '');
            if(line.length==0){
                continue;
            }
            let [addr, amount] = line.split(',');
            console.log(19,parseFloat(amount).toFixed(4))
            props.setRecievers(oldArr => [...oldArr, {address: String(addr), amount:  parseFloat(amount).toFixed(4) }]);
            
        }
    }

    return(
        <div>
           
            <p>Conflux Espace Batch Transfer tool  </p>
            <table className='table'>
                <tr>
                    <td>PPI</td>
                    <td>0x22f41abf77905f50df398f21213290597e7414dd</td>
                </tr>
                <tr>
                    <td>ABC</td>
                    <td>0x905f2202003453006eaf975699545f2e909079b8</td>
                </tr>
            </table>

           <h2> Step 1:            Input the erc token name, if empty, it will send CFX.</h2>
            <input 
            onChange={ e => props.setTokenAddress(e.target.value) }
            value={props.tokenAddress} 
            type='text' 
            name="ERC20" 
            size='50' 
            
            />
            <br/>
            <h2>Step 2:  Add Recipients Addresses and amount of token to send </h2>
            eg:<br/>
            0xa2bf6c5395a9fb1bBdD0C35C78CA016c6fEe6bdC,12<br/>0x2b0bAC6141dDc7b6ec1e30592c29Cae10ebEAaC6,12.345
            <div style={{height: '200px'}}>
                <CodeMirror
                      value= ''
                      onChange= {(e) => {getContent(e)}}
                      style={{width:'50% !important'}}
                      options={{
                        theme: 'monokai',
                        keyMap: 'sublime',
                        lineNumbers: true,
                        mode: 'javascript',
                      }}
                />
            </div>
        </div>
    );
}

export default Details;
