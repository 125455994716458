//Multi Sender Contract
const contract = {
  address: '0x75bA6F6Db38f319A38E9C7126026b0Ed9a674f60',
  abi: [
    {
      "inputs": [],
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {
      "inputs": [
        {
          "internalType": "address payable[]",
          "name": "addrs",
          "type": "address[]"
        },
        {
          "internalType": "uint256[]",
          "name": "amounts",
          "type": "uint256[]"
        },
        {
          "internalType": "address",
          "name": "_token",
          "type": "address"
        }
      ],
      "name": "multiSender",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }

,{
  "inputs": [
    {
      "internalType": "address payable[]",
      "name": "addrs",
      "type": "address[]"
    },
    {
      "internalType": "uint256[]",
      "name": "amounts",
      "type": "uint256[]"
    }
  ],
  "name": "multiSenderBase",
  "outputs": [],
  "stateMutability": "payable",
  "type": "function"
}

  ]
}

export default contract;
